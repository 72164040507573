
function Footer () {
        return <div>
            <footer>
                <p className="Keskitetty">Kokkolan Metallityöväen Ammattiosasto ry.67</p>
                <p className="Keskitetty">Perustettu 10.6.1917</p>
                <p className="Allekirjoitus">© 2021 Jaakko Niininen</p>
            </footer>
        </div>
    }


export default Footer;